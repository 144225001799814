<div
  *ngIf="illustrationUrl; else noIllustration"
  class="illustration"
  [ngStyle]="{ 'background-image': 'url(' + illustrationUrl + ')' }"></div>

<ng-template #noIllustration>
  <div class="es--icon {{ 'is_' + iconStatus }}">
    <div class="icon--wrapper">
      <account-sw-icon [icon]="icon" />
    </div>
  </div>
</ng-template>

<div class="es--desc">
  <ng-template [ngIf]="!textAsHTML">
    <h3 class="es--headline">{{ heading | translate }}</h3>
    <p class="es--subheadline" *ngIf="subheading">{{ subheading | translate }}</p>
  </ng-template>

  <ng-template [ngIf]="textAsHTML">
    <h3 class="es--headline" [innerHTML]="heading | translate"></h3>
    <p class="es--subheadline" *ngIf="subheading" [innerHTML]="subheading | translate"></p>
  </ng-template>

  <div class="es--content">
    <ng-content />
  </div>
</div>
